// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-advertise-js": () => import("./../../src/pages/advertise.js" /* webpackChunkName: "component---src-pages-advertise-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-single-js": () => import("./../../src/pages/blog-single.js" /* webpackChunkName: "component---src-pages-blog-single-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-js": () => import("./../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-default-js": () => import("./../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */)
}

